<template>
	<v-menu :close-on-content-click="false" offset-y v-model="status" :open-on-hover="!touch" offset-overflow>
		<template v-slot:activator="{ on }">
			<v-chip
				v-on="on"
				:close="!touch"
				@click="onClick"
				:close-icon="status ? 'icon-close' : 'icon-down'"
			>{{ getTitle(title, [...items.top, ...items.data], active) }}</v-chip>
		</template>
		<v-card class="filter">
			<v-col align="center" justify="center" class="list-main">
				<v-progress-circular indeterminate v-if="!facet || !items.data.length"></v-progress-circular>
				<template v-if="facet && items.top && items.top.length">
					<div>Популярные:</div>
					<div>
						<v-chip-group :multiple="multiple" column active-class="primary--text" v-model="active">
							<template v-for="(item, id) in items.top">
								<v-chip v-if="facet[item.value]" filter outlined :key="id" :value="item.value">
									<span v-html="item.text" class="text" />
									<v-avatar right class="green lighten-4 black--text">{{ counter(facet[item.value]) }}</v-avatar>
								</v-chip>
							</template>
						</v-chip-group>
					</div>
					<v-divider />
				</template>
				<v-chip-group :multiple="multiple" column active-class="primary--text" v-model="active" v-if="facet && items.data.length">
					<template v-for="(item, id) in items.data">
						<v-chip
							v-if="facet[item.value] && facet[item.value] > th"
							small
							filter
							outlined
							:key="id"
							:value="item.value"
						>
							<span v-html="item.text" class="text" />
							<v-avatar right class="green lighten-4 black--text">{{ counter(facet[item.value]) }}</v-avatar>
						</v-chip>
					</template>
				</v-chip-group>
			</v-col>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	props: ["items", "default", "title", "multiple", "facet", "threshold"],
	data() {
		return {
			active: null,
			status: null,
			th: 0,
			touch: false
		};
	},
	created() {
		this.touch = matchMedia('(hover: none), (pointer: coarse)').matches;
		this.active = this.default;
		if (this.threshold) this.th = this.threshold;
	},
	watch: {
		status: function(value) {
			if (value) this.$emit("onOpen");
		},
		active: function(value) {
			this.$emit("active", value);
		}
	},
	methods: {
		getTitle: function(name, list, selected) {
			if (selected && list.length) {
				let tags = {};
				if (typeof selected == "object") {
					tags = list.filter(item => {
						return selected.indexOf(item.value) != -1;
					});
				} else {
					tags = list.filter(item => {
						return selected == item.value;
					});
				}

				if (tags.length) {
					return tags.map(item => item.text).join(", ");
				}
			}
			return name;
		},
		counter(value) {
			if (value < 1000) return value;
			return "99+";
		},
		onClick() {
			if (this.status) this.status = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.filter {
	min-width: 300px;
	max-height: 70vh;
	overflow-y: auto;
	overflow-x: hidden;
}
.filter .v-text-field {
	padding-top: 0px;
	margin-top: 0px;
}
.filter .text {
	width: 120px;
	overflow: hidden;
}
.list-main {
	margin-left: 30px;
	margin-right: 20px;
}
.filter ::v-deep {
	.v-slide-group__content span {
		width: 180px;
		margin-right: 10px;
		margin-left: 10px;
		margin: 5px;
	}
	.v-avatar {
		font-size: 11px;
	}
}
</style>