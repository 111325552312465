<template>
	<v-container grid-list-lg fluid>
		<v-layout row justify-center>
			<v-slide-group multiple show-arrows>
				<v-slide-item v-for="person in items" :key="person.id">
					<v-card :to="{ name: 'person', params: { id: person.id }}" outlined>
						<v-card-title class="text-sm-body-2">
						{{ getPersonName(person) }}
						</v-card-title>
						<v-card-subtitle class="text-center">
						{{ getRoles(person) }}
						</v-card-subtitle>
					</v-card>
				</v-slide-item>
			</v-slide-group>
		</v-layout>
	</v-container>
</template>
<script>
import { Helper } from "../mixins/Helper";

export default {
	props: ["items"],
	mixins: [Helper],
	data() {
		return {};
	},
	watch: {},
	methods: {
		getRoles(person) {
			let roles = person.roles.split(','), roles_ru = [];
			for (const role of roles) {
				if (role == 'actor') roles_ru.push('Актёр');
				else if (role == 'director') roles_ru.push('Режиссёр');
				else if (role == 'writer') roles_ru.push('Сценарист');
			}
			return roles_ru.join(' / ');
		}
	}
};
</script>
<style scoped>
</style>
