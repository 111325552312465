<template>
	<v-container fluid>
		<Progress :error="error" :loading="loading" type="overlay"></Progress>
		<v-flex xs12>
			<v-sheet class="filter-container">
				<v-layout class="filter" align-center justify-center wrap>
					<div>
						<filter-list
							:items="{data: categories, top: []}"
							title="Жанр"
							:default="filter.category"
							:multiple="true"
							:facet="facet"
							@active="toggleCategory"
							@onOpen="getFacet"
						></filter-list>
					</div>
					<div>
						<v-menu
							:close-on-content-click="false"
							offset-y
							:open-on-hover="!touch"
							v-model="filterStatus.year"
							min-width="300"
						>
							<template v-slot:activator="{ on }">
								<v-chip
									v-on="on"
									:close="!touch"
									:close-icon="filterStatus.year ? 'icon-close' : 'icon-down'"
								>{{ filterValue.year }}</v-chip>
							</template>
							<v-card class="filter">
								<div>
									<v-chip-group column active-class="primary--text" v-model="filterYear">
										<template v-for="year in lastYears">
											<v-chip small filter outlined :value="year" :key="year">
												<span v-html="year" class="text" />
											</v-chip>
										</template>
									</v-chip-group>
								</div>
								<v-divider />
								<v-range-slider v-model="years" :min="1910" :max="curYear" @change="onYears" messages="Год">
									<template v-slot:prepend>
										<v-text-field
											v-model="years_input[0]"
											class="mt-0 pt-0"
											hide-details
											single-line
											type="number"
											style="width: 55px"
										></v-text-field>
									</template>
									<template v-slot:append>
										<v-text-field
											v-model="years_input[1]"
											class="mt-0 pt-0"
											hide-details
											single-line
											type="number"
											style="width: 55px"
										></v-text-field>
									</template>
								</v-range-slider>
							</v-card>
						</v-menu>
					</div>
					<div>
						<filter-list
							:items="countries"
							title="Страна"
							:default="filter.country"
							:facet="facet"
							:multiple="true"
							@onOpen="getCountries"
							@active="toggleCountry"
						></filter-list>
					</div>
					<div>
						<filter-list
							:items="studios"
							title="Студия"
							:default="filter.studio"
							:facet="facet"
							threshold="2"
							:multiple="true"
							@onOpen="getStudios"
							@active="toggleStudio"
						></filter-list>
					</div>
					<div v-if="!filter.query">
						<v-select
							id="sort"
							hint="Сортировка"
							:items="sortItems"
							item-text="val"
							item-value="key"
							persistent-hint
							single-line
							v-model="filter.sort"
						></v-select>
					</div>
				</v-layout>
			</v-sheet>
			<person-list v-if="persons" :items="persons" />
		</v-flex>

		<v-flex xs12>
			<channel-list :items="items" :showYear="showYear"></channel-list>
		</v-flex>
		<v-flex xs12 class="text-md-center scroll">
			<scroll-pagination v-if="hasMorePage" :page="page" :loading="loading" @page="onScroll"></scroll-pagination>
		</v-flex>
	</v-container>
</template>

<script>
import ChannelList from "../components/channel-list.vue";
import ScrollPagination from "../components/helper/scroll-pagination.vue";
import FilterList from "../components/helper/filter-list.vue";
import PersonList from "../components/person-list.vue";

export default {
	components: { ChannelList, ScrollPagination, FilterList, PersonList },
	data() {
		var curYear = new Date().getFullYear() + 1,
			minYear = 1910;
		return {
			loading: false,
			items: null,
			error: null,
			curYear: curYear,
			minYear: minYear,
			page: 1,
			years: [minYear, curYear],
			years_input: [minYear, curYear],
			meta: { per_page: 0, total: 0, search: false },
			hasMorePage: false,
			showYear: false,
			countries: {
				top: [],
				data: []
			},
			studios: {
				top: [],
				data: []
			},
			filterYear: null,
			filter: {
				section: [],
				sort: "",
				category: [],
				years: [minYear, curYear],
				query: "",
				country: [],
				studio: []
			},
			filterStatus: {
				year: false
			},
			filterValue: {
				year: "Год"
			},
			lastYears: [],
			persons: [],
			facet: [],
			facetLoading: false,
			facetToLoad: false,
			touch: false
		};
	},
	created() {
		var t = this;
		t.touch = matchMedia('(hover: none), (pointer: coarse)').matches;
		t.sortItems = [
			{ key: "", val: "По просмотрам" },
			{ key: "top", val: "По просмотрам за всё время" },
			{ key: "fresh", val: "По обновлению" },
			{ key: "last", val: "По дате добавления" },
			{ key: "kinopoisk", val: "По рейтингу кинопоиска" },
			{ key: "imdb", val: "По рейтингу IMDB" }
		];

		for (let year = t.curYear - 1; year > t.curYear - 6; year--) {
			t.lastYears.push(year);
		}

		t.queryToFilter(t.$route);
	},
	watch: {
		"filter.section": {
			handler: function(sections) {
				if (!sections) return;
				if (this.$route.params.section == "all") return;
				if (this.$route.params.section == sections.join(".")) return;
				this.onParams("section", sections.join("."));
			}
		},
		"filter.sort": {
			handler: function(sort) {
				this.onParams("sort", sort ? sort : undefined);
			}
		},
		years_input(val) {
			if (this.yearsValidate(val)) {
				this.showYear = true;
				this.years = val;
				this.filter.years = val;
				this.filterValue.year =
					val != [this.minYear, this.curYear]
						? val[0] + " - " + val[1]
						: "Год";
				this.onYears(val);
			}
		},
		filterYear(val) {
			//if (val == this.filterYear) return;
			if (typeof val == 'undefined') {
				this.onYears([this.minYear, this.curYear]);
				return;
			}

			this.onYears([val, val]);
		}
	},
	methods: {
		fetchData(page, pagination) {
			var t = this;
			t.error = null;
			if (!pagination) t.loading = true;

			var body = JSON.stringify(t.filter);
			if (page) body = body.replace(/(})$/, ',"page":' + page + "}");

			window.ajax("/api/channel/list", { body: body }, function(
				data,
				error
			) {
				if (!pagination) t.loading = false;
				if (!error) {
					t.hasMorePage = data.meta.hasMore;
					if (page == 1) {
						t.items = data.data;
						t.facet = data.facet;
						t.persons = data.persons;
						if (t.facetToLoad) {t.facetToLoad = false; t.getFacet();}
					} else {
						t.items = [...t.items, ...data.data];
					}
				} else {
					t.error = data;
					t.facet = [];
					t.persons = [];
				}
			});
		},
		getFacet() {
			let t = this;
			if (Object.keys(t.facet).length) return;
			if (t.facetLoading) return;
			t.facetLoading = true;

			var body = JSON.stringify(t.filter);

			window.ajax("/api/channel/facet", { body: body }, function(
				data,
				error
			) {
				t.facetLoading = false;
				if (!error) {
					t.facet = data.facet;
				} else {
					t.error = data;
					t.facet = [];
				}
			});
		},
		getCountries() {
			let t = this;
			if (t.countries.top.length) return;
			t.getFacet();

			var body = JSON.stringify(t.filter);
			window.ajax("/api/getCountries", { body: body }, function(
				data,
				error
			) {
				if (!error) {
					t.countries = data;
				}
			});
		},
		getStudios() {
			let t = this;
			if (t.studios.length) return;
			t.getFacet();

			var body = JSON.stringify(t.filter);
			window.ajax("/api/getStudios", { body: body }, function(
				data,
				error
			) {
				if (!error) {
					t.studios = data;
				}
			});
		},
		onScroll(page) {
			this.fetchData(page, 1);
			this.page = page;
		},
		onYears(val) {
			this.onQuery("years", val[0] + "-" + val[1]);
		},
		onQuery(name, value) {
			let route = this.$router.currentRoute;
			let query = Object.assign({}, route.query);

			query[name] = value ? value : undefined;
			this.$router
				.replace({
					name: "channelList",
					params: route.params,
					query: query
				}).catch(err => {console.log(err);});
		},
		onParams(name, value) {
			let route = this.$router.currentRoute;
			let params = Object.assign({}, route.params);

			params[name] = value;
			this.$router
				.replace({
					name: "channelList",
					params: params,
					query: route.query
				}).catch(err => {console.log(err);});
		},
		queryToFilter(route) {
			let t = this;

			t.filter.sort = route.params.sort ? route.params.sort : "";

			let sections = route.params.section.split("."),
				names = [];
			if (route.params.section == "all" || route.params.section == "") {
				t.filter.section = sections = ["series", "anime", "movie"];
			} else {
				if (t.filter.section.join(".") != route.params.section)
					t.filter.section = sections;
			}

			if (sections.includes("movie")) names.push("Фильмы");
			if (sections.includes("series")) names.push("Сериалы");
			if (sections.includes("anime")) names.push("Аниме");

			document.title = names.join(", ");

			if (route.query.years) {
				if (
					typeof route.query.years != "number" ||
					typeof route.query.years == "string"
				) {
					let years = route.query.years.split("-");
					if (!years[1]) t.filter.years = [years[0], years[0]];
					else t.filter.years = [years[0], years[1]];
				} else t.filter.years = route.query.years;
				t.years = t.filter.years;
				t.years_input = t.filter.years;
				this.showYear = true;

				if (t.years[0] == t.years[1] && t.filterYear != t.years[0] && t.lastYears.indexOf(t.years[0])) {
					t.filterYear = t.years[0];
				}
			}

			["category", "country", "studio"].forEach(name => {
				let value = [];
				if (route.query[name]) {
					value = route.query[name]
						.split(".")
						.map(val => parseInt(val));
					if (name == "country") t.getCountries();
					else if (name == "studio") t.getStudios();
				}
				t.filter[name] = value;
			});
			["tag"].forEach(name => {
				let value = null;
				if (route.query[name]) value = route.query[name];
				t.filter[name] = value;
			});

			if (typeof route.query.search == "string" && route.query.search != '') {
				t.filter.query = route.query.search;
				document.title = "Поиск";
			}
			else if (typeof route.query.search == "string" && route.query.search == '') {
				t.filter.query = route.query.search;
			}

			t.page = 1;
			t.persons = [];
			t.fetchData(1);
		},
		filterTagBySection(tags) {
			let result = [];
			for (let idx in tags) {
				let in_section = false,
					tag = tags[idx],
					sections = tag.section.split(",");
				for (let section in sections) {
					let section_id = sections[section];
					if (this.filter.section.indexOf(section_id) != -1)
						in_section = true;
				}
				if (in_section) result.push(tag);
			}
			return result;
		},
		unselect(type, tag) {
			this.filter[type] = this.filter[type].filter(tag1 => {
				return tag1 != tag.value;
			});
		},
		yearsValidate(years) {
			if (
				years[0] < 1910 ||
				years[1] < 1910 ||
				years[0] > years[1] ||
				years[0] > this.curYear ||
				years[1] > this.curYear
			)
				return false;
			return true;
		},
		toggleCountry(value) {
			this.toggleTag(value, "country");
		},
		toggleStudio(value) {
			this.toggleTag(value, "studio");
		},
		toggleCategory(value) {
			this.toggleTag(value, "category");
		},
		toggleTag(value, type) {
			if (typeof value == "undefined") return;
			if (
				value.length == this.filter[type].length &&
				value.length == 0
			)
				return;
			this.facetToLoad = true;
			this.onQuery(
				type,
				typeof value == "object" ? value.join(".") : value
			);
		}
	},
	computed: {
		categories: function() {
			return this.filterTagBySection(this.$store.state.misc.categories);
		}
	},
	beforeRouteUpdate(to, from, next) {
		if (from.query.years && to.query.years) {
			if (from.query.years.indexOf("-") == -1) return;
		}
		this.page = 1;
		this.queryToFilter(to);
		next();
	}
};
</script>
<style lang="scss" scoped>
.filter > div {
	padding: 0 5px;
}
@media only screen and (max-width: 600px) {
	.filter-container {
		max-width: 100%;
	}
	.filter > div {
		padding-top: 10px;
	}
}
.v-input__slider {
	padding-top: 10px;
}
.container.bar-wrap {
	padding: 10px;
}
.v-chip-group .v-chip {
	margin: 0;
}
.container ::v-deep {
	.v-input__slider .v-messages__message {
		margin-top: -5px;
	}
	.v-input--range-slider
		.v-text-field
		> .v-input__control
		> .v-input__slot:before {
		width: 0px;
	}
	.v-input--range-slider input[type="number"]::-webkit-inner-spin-button,
	.v-input--range-slider input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.v-input__prepend-outer input {
		text-align: right;
	}
}
</style>